<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="message_box mt-3">
				<span class="txt_body1 " v-html="message"> </span>
			</div>
		</v-container>
	</div>
</template>

<script>
/**
 * 인증 정상, 미납연체 고객 안내 화면
 */

export default {
	name: 'CustVerifyUnComplete',
	data() {
		return {
			message:
				'고객 인증을 정상적으로 완료했으나, <br />' +
				'미납/연체 금액이 있어 주문 진행이' +
				'<br />' +
				'불가능합니다.' +
				'<br />' +
				'미납/연체 금액의 납부를 먼저 부탁드립니다. <br />' +
				'감사합니다.'
		}
	},
	created() {
		const params = this.$route.query.params
		if (params && params.message !== '') {
			this.message = params.message
		}
	}
}
</script>
